<template>
    <div class="px-2">
        <v-container>
        <v-row no-gutters>
            <v-col>
                <p class="display-1 mb-2">Account verwijderen</p>
                <div>{{intro}}</div>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="py-4">
                <v-btn color="primary" block @click.stop="sendDeleteRequest()" :disabled="disableBtn">
                    Stuur bevestigingslink naar e-mailadres
                </v-btn>
            </v-col>
        </v-row>
        </v-container>
    </div>
</template>

<script>
import MetaTagsMixin from "@/mixins/metatags.mixin";

export default {
    name: 'AccountVerwijderen',
    components: 
    {
    },
    data: () => ({
        disableBtn: false,
        intro: "Druk op onderstaande knop om een link aan te vragen waarmee u uw account kunt verwijderen. U ontvangt dan van ons een e-mail die u kunt bevestigen. Uw account wordt dan verwijderd. Deze wijziging kan niet ongedaan worden gemaakt! Het is dan niet meer mogelijk om uw oude informatie in te zien.",
    }),
    mounted () 
    {
        this.$store.commit('setFooterVisibility', true);
    },
    mixins: [MetaTagsMixin],
    metaInfo() {
        return {
        title: '| Account Verwijderen',
        meta: [
            this.getKeywords(`Account Verwijderen`),
            this.getMetaDescription(`Account Verwijderen`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`Account Verwijderen`, `Roadpursuit Account Verwijderen`, '', ''),
        ]
        };
    },
    methods: 
    {
        sendDeleteRequest()
        {
            this.disableBtn = true;
            this.$store.dispatch('accountModule/requestAccountDeletionLink')
            .then(() => {
               this.$store.commit('dialog/showDialog', 'We hebben u een e-mail gestuurd. Bekijk deze om uw accountverwijdering te bevestigen.');
            })
            .catch(error => {
                this.$store.commit('dialog/showDialog', error);
                this.disableBtn = false;
            })
        }
    }
}
</script>
